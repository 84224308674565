'use client';

import dynamic from 'next/dynamic';

const App = dynamic(() => import('@/components/App'), { ssr: false });

/*
 * SPA Client-only entry point of the
 * CRA app code before we moved to next.js
 */
export function ClientOnlySPA() {
  return <App />;
}
